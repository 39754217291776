#intro {
    display: auto;
    flex-direction: column;
    align-items: center;    
    text-align: center;
    margin: auto;   
    max-width: 1000px;
    padding-top: 200px; 
    min-height: 100vh;
}

.intro-title {
    font-family: "Inter", sans-serif;
    color: black;
    font-size: 76px;
    font-weight: semibold;
    line-height: 1.1;
}

.intro-name {
    font-family: "Inter", sans-serif;
    color: var(--custom-text-color);
    font-size: 76px;
    font-weight: semibold;
    line-height: 1.1;
}

.intro-subtitle {
    font-family: "Inter", sans-serif;
    color: black;
    font-size: 48px;
    font-weight: 500;
    text-align: center;
    margin-top: -10px;
}

.intro-desc {
    font-family: "Inter", sans-serif;
    color: black;
    font-size: 24px;
    align-self: center;
    text-align: center;
    max-width: auto;   
    padding-top: 10px;
    padding-bottom: 50px;
}

.intro-contact{
    font-size: 22px;
    font-weight: bolder;
    font-family: "Inter", sans-serif;
    align-self: center;
    justify-content: space-evenly;
    padding: 10px 30px;
    cursor: pointer;
    border: 1px solid var(--custom-text-color);
    border-radius: 4px;
}

.intro-contact:hover {
    background-color: white;
}

.typed-cursor {
    opacity: 1;
    font-size: 70px;
    color: var(--custom-text-color);
    align-self: flex-end;
    padding-left: 5px;
}

.intro-img {
    width: 250px !important;
    height: 100% !important;
    padding-bottom: 20px;
}

b {
    font-size: 17px;
}

@media only screen and (max-width: 600px) {
    #intro {
      min-height: unset;
      padding-left: unset;
      padding-top: unset;
      margin: 0;
      align-items: center;
    }
  
    .intro-title {
      font-size: 46px;
      font-weight: 800;
    }
    .intro-name {
      font-size: 46px;
    }
    .intro-subtitle {
      margin: 0;
      font-size: 28px;
    }
  
    .intro-desc {
      font-size: 18px;
      max-width: unset;
      padding-bottom: 40px;
    }
  
    .intro-contact {
      font-size: 16px;
    }
  }

